// En réalité depuis la V2, ne sert qu'aux pages Portfolio

import { useState, useEffect } from "react";

//import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import "./WorksHome.css";
import { More } from "../../../components/svg/Sprite";

export default function WorksHome({ page }) {
	const [datas, setDatas] = useState([{}]);
	
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_BASEURL_CMS}/api/collections/entries/portfolio?token=${process.env.REACT_APP_APIKEY_PORTFOLIO}&filter[publish]=true&sort[order]=1`)
		.then(d => {
			setDatas([d.data.entries][0])
		});
	}, [])
		
	return (
		<>
			<div className="bloc-content-works">
				{
					datas.length < 2 ? 
					(
						<></>
					) 
					: 
					(
						<>
							<ul className="bloc-content-works-list">
								{
									datas && datas.map((d) => {
										return (
											<li key={uuidv4()} className="items">
												{/* <Link to={`/portfolio/${d.slug}`} >{d.title}</Link> */}
												<NavLink activeClassName='is-active' to={`/portfolio/${d.slug}`} >{d.title}</NavLink>
											</li>
										);
									})
								}
								
								{
									/* page === "portfolio" && <li key={uuidv4()} className="items close"><Link to="/"><More/></Link></li> */
									page === "portfolio" && <li key={uuidv4()} className="items close"><NavLink to="/"><More/></NavLink></li>
								}
							</ul>
						</>
					)
				}
			</div>
		</>
	);
}