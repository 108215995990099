import { Link } from "react-router-dom"; 

import './MenuMobile.css';

import { Logo, More } from "../../svg/Sprite";

function handleCloseMenuClick(refMenuMobile) {
	refMenuMobile.current.classList.toggle("show");
}

export default function MenuMobile({refMenuMobile}) {
	return (
		<>
			<div id="menu-container" className="menu-container">
				<div id="logo" className="logo">
					<Logo/>
				</div>
				
				<Link to="/" onClick={ () => {handleCloseMenuClick(refMenuMobile)} }>Home</Link>
				<Link to="/studio" onClick={ () => {handleCloseMenuClick(refMenuMobile)} }>Studio</Link>
				<Link to="/contact" onClick={ () => {handleCloseMenuClick(refMenuMobile)} }>Contact</Link>
				{/*<Link to="/shopmobile" onClick={ () => {handleCloseMenuClick(refMenuMobile)} }>Shop</Link>*/}
				<a href="http://shop.pikartzo.com" target="_blank" rel="noopener noreferrer">Shop</a>
				{/*<Link to="/resume" onClick={ () => {handleCloseMenuClick(refMenuMobile)} }>Resume</Link>*/}
				<a href="http://www.instagram.com/pikartzo" target="_blank" rel="noopener noreferrer">Instagram</a>
				
				<div id="close" className="close" onClick={ () => {handleCloseMenuClick(refMenuMobile)} }>
					<More/>
				</div>
			</div>
		</>
	);
}