import { useState, useEffect } from "react";
import axios from "axios";

import "./Contact.css";

import Form from "../../components/form/Form";

export default function Contact() {
	function showNetworks(itemsHide) {		
		itemsHide.forEach((itemHide) => {
			itemHide.classList.remove("item-hide");
		});
	}
	
	const [datas, setDatas] = useState({
		title_contact: "Data loading...",
		content_informations_and_social_networks: "<p>Data loading...</p>",
		title_form: "Data loading...",
	});
	
	useEffect(() => {
		document.title = "Contact | Pikartzo";
		document.getElementById("body").classList.add("contact");
		
		axios.get(`${process.env.REACT_APP_BASEURL_CMS}/api/singletons/get/contact?token=${process.env.REACT_APP_APIKEY_CONTACT}`)
		.then(d => {
			setDatas(d.data);
		})
		
		const moreNetworks = document.getElementById("more-network");
		const moreNetworksHide = document.querySelectorAll(".item-hide");
		
		if (moreNetworks) {
			moreNetworks.addEventListener("click", () => { 
				moreNetworks.classList.add("hide");
				showNetworks(moreNetworksHide) 
			} );
		}
		
		return () => {
			document.getElementById("body").classList.remove("contact");
		}
	  }, [datas.content_informations_and_social_networks]);
	
	return (
		<div id="container" className="container">
			<div id="content" className="content">
				<div className="bloc-content">
					<div className="title" dangerouslySetInnerHTML = {{__html: datas.title_contact}}/>
					<div className="information" dangerouslySetInnerHTML = {{__html: datas.content_informations_and_social_networks}}/>
				</div>

				<div className="bloc-content">
					<div className="title" dangerouslySetInnerHTML = {{__html: datas.title_form}}/>
					<div className="form"><Form/></div>
				</div>
			</div>
		</div>
	);
}