import { useCallback, useState, useEffect } from "react";
import axios from "axios";

import "./Studio.css";

export default function Studio() {
	const [datas, setDatas] = useState({
		title_publications: "Data loading...",
		content_publications: "<p>Data loading...</p>",
		title_clients: "Data loading...",
		content_clients: "<p>Data loading...</p>",
		title_presentation: "Data loading...",
		content_presentation: "<p>Data loading...</p>",
	});
		
	const [background, setBackground] = useState({
		show: false,
		img: null,
	});
	
	function handleSetBackground(e) {
		setBackground({
			show: true,
			img: e.target.dataset.background,
		});
	}
	
	function handleRemoveBackground() {
		setBackground({
			show: false,
			img: null,
		});
	}
	
	function isBackground() {
		return background.show;
	}
	
	const backgroundDiv = useCallback(node => {
		if (node !== null) {
			node.addEventListener("click", handleRemoveBackground);
		}
	}, []);
	
	useEffect(() => {
		document.title = "Studio | Pikartzo";
		document.getElementById("body").classList.add("studio");
				
		axios.get(`${process.env.REACT_APP_BASEURL_CMS}/api/singletons/get/studio?token=${process.env.REACT_APP_APIKEY_STUDIO}`)
		.then(d => {
			setDatas(d.data);
		})
		
		const hoverLinks = [...document.getElementsByClassName("hover-link")];
		
		hoverLinks.forEach((hoverLink) => {
			hoverLink.addEventListener("click", (event) => { handleSetBackground(event) } );
		});
				
		return () => {
			document.getElementById("body").classList.remove("studio");
		}
	}, [datas.content_presentation]);
			
	return (	
		<>					
			<div id="container" className="container">	
				{
					isBackground() && 
					<div ref={backgroundDiv} id="background" className="background"><div id="background-content" className="background-content"><img src={background.img} alt="images studio" /></div></div>
				}
							
				{
					datas && 
						<>		
							<div id="content" className="content">
								<div className="bloc-content">
									<div className="title" dangerouslySetInnerHTML = {{__html: datas.title_presentation}}/>
									<div className="information" dangerouslySetInnerHTML = {{__html: datas.content_presentation}}/>
								</div>
								
								<div className="bloc-content">
									<div className="title" dangerouslySetInnerHTML = {{__html: datas.title_clients}}/>
									<div className="information" dangerouslySetInnerHTML = {{__html: datas.content_clients}}/>
								</div>
								
								<div className="bloc-content">
									<div className="title" dangerouslySetInnerHTML = {{__html: datas.title_services}}/>
									<div className="information" dangerouslySetInnerHTML = {{__html: datas.content_services}}/>
								</div>
								
								<div className="bloc-content">
									<div className="title" dangerouslySetInnerHTML = {{__html: datas.title_publications}}/>
									<div className="information" dangerouslySetInnerHTML = {{__html: datas.content_publications}}/>
								</div>
							</div>
						</>
				}
			</div>
		</>
	);
}