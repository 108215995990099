import { useEffect, useCallback } from "react";

import { Link } from "react-router-dom"; 

import './Footer.css';

import { More } from "../svg/Sprite";
import MenuDesktop from "../menu/menuDesktop/MenuDesktop";

// V3 : Revoir complètement le fonctionnement du menu pour que ça soit plus simple à maintenir
// NOTE : Maintenant, dans la V2 : quand le menu est ouvert sur Desktop, il est fermé sur Mobile.

export default function Footer({ refOpenMenu, refOpenMenuMobile, refMenuMobile, refMenuDesktop }) {	
	const handleOpenMenuClick = useCallback(() => {
		refOpenMenu.current.classList.toggle("opened");
		refMenuDesktop.current.classList.toggle("show");
    }, [refOpenMenu, refMenuDesktop]);
	
	const handleOpenMenuClickMobile = useCallback(() => {
		refMenuMobile.current.classList.toggle("show");
    }, [refMenuMobile]);
	
	useEffect(() => {
		const timer = setTimeout(() => {
			handleOpenMenuClick();
		}, 1125);
		return () => clearTimeout(timer);
	}, [handleOpenMenuClick]);
	
	return (
		<>
			<div id="footer" className="footer">
				<div id="openMenuMobile" className="openMenuMobile" ref={refOpenMenuMobile} onClick={handleOpenMenuClickMobile}>
					<More/>
				</div>
				
				<div id="openMenu" className="openMenu" ref={refOpenMenu} onClick={handleOpenMenuClick}>
					<More/>
				</div>
				
				<nav id="menu-desktop" className="menu-desktop" ref={refMenuDesktop}>
					<MenuDesktop/>
				</nav>

				<div id="credits" className="credits">
					© {new Date().getFullYear()} Pikartzo&trade; - All rights reserved - <Link to="/impressum">Impressum</Link>
				</div>
			</div>
		</>
	);
}