import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import "./newPresentationWorksHome.css";
import { Cross } from "../../../components/svg/Sprite";

export default function NewPresentationWorksHome({ page }) {
	const [datas, setDatas] = useState([{}]);
	
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_BASEURL_CMS}/api/collections/entries/portfolio?token=${process.env.REACT_APP_APIKEY_PORTFOLIO}&filter[publish]=true&sort[order]=1`)
		.then(d => {
			setDatas([d.data.entries][0])
		});
	}, [])
			
	return (
		<>
			<div className="bloc-content-newPresentationWorks">
				{
					datas.length < 2 ? 
					(
						<></>
					) 
					: 
					(
						<>
							<div className="bloc-content-newPresentationWorks-list">
								{
									datas && datas.map((d, index) => {
										return (
											<div key={uuidv4()} className="items">
												<div className="bloc-cross">
													<div className="cross"><Cross/></div>
													{/* https://stackoverflow.com/questions/72380512/how-can-i-use-dynamic-css-variables-in-react  */}
													<Link style={{'--indexPreview': (index + 1) * 0.5}} className="preview" to={`/portfolio/${d.slug}`} >
														{/* https://stackoverflow.com/questions/72148698/how-to-access-an-object-inside-another-object-in-a-map-in-react */}
														<img src={`${process.env.REACT_APP_BASEURL_CMS}/${d?.preview?.path}`} alt={uuidv4()} />
													</Link>
												</div>
											</div>
										);
									})
								}
								
							</div>
						</>
					)
				}
			</div>
		</>
	);
}