import { useRef, useState, useEffect, useCallback } from "react";
import { isMobileOnly } from "react-device-detect";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import "./Works.css";

import WorksHome from "../home/worksHome/WorksHome";
import axios from "axios";

function handleError() {
	return window.location.href = "/error";
}

function contentVideoOrImage(dataField) {
	if (dataField.field.type === "text") {
		return (
			<div className="vimeo">
				<iframe src={`${process.env.REACT_APP_BASEURL_VIMEO}/${dataField.value}`} seamless allow="fullscreen" title={uuidv4()}></iframe> 
			</div>
		)
	}
	else {
		return (
			<img src={`${process.env.REACT_APP_BASEURL_CMS}/${dataField.value.path}`} alt={uuidv4()} />
		)
	}
}

function redirectUserIsOnMobile() {
	if (isMobileOnly) {
		return <Redirect to="/"/>
	}
}
	//alt={`${data[0].title} / Élément n°${index + 1}`}
	
	// title={`${data[0].title} / Élément n°${index + 1}`}
	// webkitAllowFullScreen mozallowfullscreen allowFullScreen ++++ https://stackoverflow.com/questions/42858199/vimeo-full-width
    
export default function Works() {	
	const [datas, setDatas] = useState([{}]);
	const refBlocContent = useRef(null);

	const location = useLocation();
	const slug = location.pathname.replace('/portfolio/', '');
	
	const titleToShow = datas[0].title ? `${datas[0].title} | Pikartzo` : `Portfolio | Pikartzo`;
	
	// console.log(`${process.env.REACT_APP_BASEURL_CMS}/api/collections/entries/portfolio?token=${process.env.REACT_APP_APIKEY_PORTFOLIO}&filter[slug]=projet-1`)
	
	const fetchDatas = useCallback((slugAPI) => {
		axios.get(`${process.env.REACT_APP_BASEURL_CMS}/api/collections/entries/portfolio?token=${process.env.REACT_APP_APIKEY_PORTFOLIO}&filter[slug]=${slugAPI}`)
		.then(d => {
			setDatas([d.data.entries][0]);
		})
		.catch(err => {
			return handleError();
		})
	}, []);
	
	useEffect(() => {
		document.title = titleToShow;
		document.getElementById("body").classList.add("portfolio");
		
		refBlocContent.current.scrollTop = 0;
		
		fetchDatas(slug);
		
		return () => {
			document.getElementById("body").classList.remove("portfolio");
		}
	}, [slug, fetchDatas, titleToShow]);
	
	return (
		<>
			{ redirectUserIsOnMobile() }
			<div id="container" className="container">
				<div id="content" className="content">
					<div className="bloc-content"><WorksHome page="portfolio"/></div>
					<div ref={refBlocContent} className="bloc-content">
						{
							datas[0].content &&
							<>
								<div className="bloc-content-medias">				
									{ 
										datas[0].content
										&& datas[0].content.map((field) => {
											return <div key={uuidv4()}>{contentVideoOrImage(field)}</div>
										})
									}
								</div>
								{
									datas[0].content && 
									<>
										<div className="bloc-content-informations">
											{
												datas[0].client && <div><b>CLIENT :</b><br/><p style={{"textTransform" : "uppercase"}}>{datas[0].client}</p></div>
											}
											
											<div>
												{
													datas[0].year && <div><b>YEAR :</b><br/><p>{datas[0].year}</p></div>
												}
												
												{
													datas[0].skills && <div style={{"marginTop" : "4em"}} dangerouslySetInnerHTML = {{__html: `<b>SKILLS :</b><br/>${datas[0].skills}`}}/>
												}
											</div>
											
											{
												datas[0].skills && <div dangerouslySetInnerHTML = {{__html: `<b>INFORMATIONS :</b><br/>${datas[0].informations}`}}/>
											}
										</div>
									</>
								}
							</>
						}
					</div>
				</div>
			</div>
		</>
	);
}