import { useState, useEffect } from "react";

import "./Home.css";
import { Cross } from "../../components/svg/Sprite";

import WorksMobileHome from "./worksMobileHome/WorksMobileHome";
import NewPresentationWorksHome from "./newPresentationWorksHome/newPresentationWorksHome";

export default function Home({ refWorksHomeClose }) {
	const [bloc, setBloc] = useState({
		works: {
			isOpen: false,
		}, 
		shop: {
			isOpen: false,
		}, 
		more: {
			isOpen: false,
		},
	});
	
	function handleToggleBloc(name, type) {
		if (type === "open") {
			setBloc({
				...bloc,
				[name]: {isOpen: true}
			});
		}
		else if (type === "close") {
			setBloc({
				...bloc,
				[name]: {isOpen: false}
			});
		}
	}
	
	useEffect(() => {
		document.title = "Home | Pikartzo";
		document.getElementById("body").classList.add("home");
		
		return () => {
			document.getElementById("body").classList.remove("home");
		}
	}, []);
	
	return (		
		<div id="container" className="container">
			<div id="content" className="content">
				<div className="bloc-worksMobile">
					<div className="bloc-cross">
						<div className="cross"><Cross/></div>
						<div onClick={() => { handleToggleBloc("works", "open")} } className="title">Tap for pleasure</div>
					</div>
					{ 
						bloc.works.isOpen 
						&& <WorksMobileHome/>
					}
				</div>
				
				<div className="bloc-works">
					{ 
						/* 
							V2 : La présentation des travaux a changé par rapport à la V1. 
							Maintenant, les travaux s'affichent directement lors de l'arrivé des visiteurs.
							Il n'y a plus les blocs Shop et More.
						*/
						<NewPresentationWorksHome />
					}
				</div>
			</div>
		</div>
	);
}