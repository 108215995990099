import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import "./WorksMobileHome.css";

export default function WorksMobileHome({ closeBloc, page }) {
	const [datas, setDatas] = useState([{}]);
	const [refresh, setRefresh] = useState(0);
	
	const fetchDatas = useCallback(() => {
		document.getElementById("body").classList.add("portfolioMobile");
		
		// console.log(`${process.env.REACT_APP_BASEURL_CMS}/api/collections/entries/portfolioMobile?token=${process.env.REACT_APP_APIKEY_PORTFOLIO}&filter[published]=true&sort[order_mobile]=1`);
		
		axios.get(`${process.env.REACT_APP_BASEURL_CMS}/api/collections/entries/portfolioMobile?token=${process.env.REACT_APP_APIKEY_PORTFOLIO}&filter[published]=true&sort[order_mobile]=1`)
		.then(d => {
			setDatas([d.data.entries][0])
		});
		
		return () => {
			document.getElementById("body").classList.remove("portfolioMobile");
		}
	}, []);
	
	function handleClickRefresh() {
		if (refresh + 1  <= datas.length - 1) {
			setRefresh(oldState => oldState + 1);
		}
		else {
			setRefresh(0);
		}
	}
	
	function changeImagePortfolioMobile(index) {
		if (datas) {
			if (datas[index].hasOwnProperty("image")) {
				if (datas[index].image.hasOwnProperty("path")) {
					return (
						<>
							<div className="image">
								<img src={`${process.env.REACT_APP_BASEURL_CMS}/${datas[refresh].image.path}`} alt={uuidv4()} />
							</div>	
						</>
					);
				}
				else {
					setRefresh(oldState => oldState + 1);
				}
			}
		}
	}
	
	useEffect(() => {
		fetchDatas();
	}, [fetchDatas])
	
	return (
		<>
			<div className="bloc-content-worksMobile" onClick={handleClickRefresh}>
				{changeImagePortfolioMobile(refresh)}
			</div>
		</>
	);
}