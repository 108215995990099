import { useEffect } from "react";

import "./Error.css";

import { Cross } from "../../components/svg/Sprite";
import ErrorMP4 from "../../assets/videos/error.mp4";
import ErrorWEBM from "../../assets/videos/error.webm";
import ErrorOGV from "../../assets/videos/error.ogv";

export default function Error() {
	useEffect(() => {
		document.title = "Error | Pikartzo";
		document.getElementById("body").classList.add("error");
		
		return () => {
			document.getElementById("body").classList.remove("error");
		}
	  }, []);
	
	return (
		<div id="container" className="container">	
			<div id="content" className="content">
				<div className="bloc-cross">
					<div className="cross"><Cross/></div>
				</div>
				
				<div className="bloc-cross">
					<div className="cross"><Cross/></div>
				</div>
				
				<div className="bloc-video" dangerouslySetInnerHTML={{__html:`<video
					loop
					muted
					autoplay
					playsinline
					preload="metadata">
							<source src="${ErrorMP4}" type="video/mp4" />
							<source src="${ErrorWEBM}" type="video/webm" />
							<source src="${ErrorOGV}" type="video/ogg" />
						</video>`
					}}>
				</div>
			</div>
		</div>
	);
}