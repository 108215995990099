import { Link } from "react-router-dom"; 

import './MenuDesktop.css';

import { Plus, Heart } from "../../svg/Sprite";

export default function MenuDesktop() {
	return (
		<>
			<div id="menu-container" className="menu-container">
				<div id="facebookMenu" className="facebookMenu">
					<a href="https://www.linkedin.com/in/pikartzo" target="_blank" rel="noopener noreferrer">
						<Plus/>
					</a>
				</div>
				
				<Link to="/">Home</Link>
				<Link to="/studio">Studio</Link>
				<Link to="/contact">Contact</Link>
				
				{/*<Link to="/shop">Shop</Link>*/}
				<a href="http://shop.pikartzo.com" target="_blank" rel="noopener noreferrer">Shop</a>
				
				<div id="heart" className="heart">
					<a href="http://www.instagram.com/pikartzo" target="_blank" rel="noopener noreferrer">
						<Heart/>
					</a>
				</div>
			</div>
		</>
	);
}