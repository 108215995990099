import { Link } from "react-router-dom"; 

import "./Header.css";

import { Logo } from "../svg/Sprite";

export default function Header() {
	return (
		<>
			<div id="header" className="header">
				<Link to="/">
					<Logo/>
				</Link>
			</div>
		</>
	);
}