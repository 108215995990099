import { useState, useEffect } from "react";
import axios from "axios";

import "./Impressum.css";

export default function Impressum() {
	const [datas, setDatas] = useState({
		title_impressum: "Data loading...",
		content_impressum: "<p>Data loading...</p>",
		title_cgv: "Data loading...",
		content_cgv: "<p>Data loading...</p>"
	});
	
	useEffect(() => {
		document.title = "Impressum | Pikartzo";
		document.getElementById("body").classList.add("impressum");
				
		axios.get(`${process.env.REACT_APP_BASEURL_CMS}/api/singletons/get/impressum?token=${process.env.REACT_APP_APIKEY_IMPRESSUM}`)
		.then(d => {
			setDatas(d.data);
		})
		
		return () => {
			document.getElementById("body").classList.remove("impressum");
		}
	  }, []);
			
	return (
		<div id="container" className="container">				
			{
				datas && 
					<>
						<div id="content" className="content">
							<div className="bloc-content">
								<div className="title" dangerouslySetInnerHTML = {{__html: datas.title_impressum}}/>
								<div className="information" dangerouslySetInnerHTML = {{__html: datas.content_impressum}}/>
							</div>
							
							<div className="bloc-content">
								<div className="title" dangerouslySetInnerHTML = {{__html: datas.title_cgv}}/>
								<div className="information" dangerouslySetInnerHTML = {{__html: datas.content_cgv}}/>
							</div>
						</div>
					</>
			}
		</div>
	);
}