//--- À FAIRE ---
		
// La V2 devient difficile à maintenir entre les Menus différents (Desktop / Mobile) 
// et les modules Shop qui ne s'affichent pas de la même façon en Dektop et en Mobile
// Les transitions entre les pages / éléments seraient aussi à revoir 
// Tout reprendre de A à Z en SSR (Next JS) surtout pour le SEO ?

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<App/>
		</Router>
	</React.StrictMode>,
	document.getElementById('app')
);