import { useRef } from "react";
import { isTablet, isMobileOnly, withOrientationChange } from "react-device-detect";
import { Switch, Route, Redirect } from "react-router-dom";

import "./index.css";

import { Logo, Smiley, Eggplant } from "./components/svg/Sprite";

import MenuMobile from "./components/menu/menuMobile/MenuMobile";
import Header from "./components/header/Header";
import Home from "./containers/home/Home";
import Works from "./containers/works/Works";
import Studio from "./containers/studio/Studio";
import Contact from "./containers/contact/Contact";
//import Shop from "./containers/shop/Shop";
//import ShopMobile from "./containers/shopMobile/ShopMobile";
import Resume from "./containers/resume/Resume";
import Impressum from "./containers/impressum/Impressum";
import Error from "./containers/error/Error";
import Footer from "./components/footer/Footer";

function App(props) {
	let refOpenMenu = useRef(null);
    let refMenuDesktop = useRef(null);
	let refMenuMobile = useRef(null);
	
	if ((isTablet && props.isPortrait) || (isMobileOnly && props.isLandscape)) {
		return (
			<>
				<div id="errorRotateSite" className="errorRotateSite">
					<div id="logo" className="logo"><Logo/></div>
					<div id="message" className="message">For your confort, rotate your device</div>
					<div id="smiley" className="smiley"><Smiley/></div>
				</div>
			</>
		);
	}
	else {	
		return (
			<>
				<div id="errorEnlargeSite" className="errorEnlargeSite">
					<div id="logo" className="logo"><Logo/></div>
					
					<div id="message-eggplant" className="message-eggplant">
						<div id="message" className="message">Sorry guys ... it's too small</div>
						<div id="eggplant" className="eggplant"><Eggplant/></div>
					</div>
					
					<div id="solution" className="solution">Move to a larger resolution for more fun</div>
				</div>
				
				<nav id="menu-mobile" className="menu-mobile" ref={refMenuMobile}>
					<MenuMobile refMenuMobile={refMenuMobile} />
				</nav>

				<Header/>
					<Switch>
						<Route exact path="/" render={() => <Home/> }/>
						<Route exact path="/portfolio" render={() => <Redirect to="/"/>}/>
						<Route path="/portfolio/:slug" render={() => <Works/>}/>
						<Route path="/studio" render={() => <Studio/>}/>
						<Route path="/contact" render={() => <Contact/>}/>
						{/*<Route path="/shop" render={() => <Shop/>}/>*/}
						{/*<Route path="/shopmobile" render={() => <ShopMobile/>}/>*/}
						<Route path="/resume" render={() => <Resume/>}/>
						<Route path="/impressum" render={() => <Impressum/>}/>
						<Route render={() => <Error/>}/>
					</Switch>
				<Footer refOpenMenu={refOpenMenu} refMenuMobile={refMenuMobile} refMenuDesktop={refMenuDesktop}/>
			</>
		);
	}
}

export default withOrientationChange(App);