import React from 'react';
import { useFormik } from 'formik';
import axios from "axios";

const Form = (props) => {
	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			message: '',
		},
		validate: values => {
			const errors = {};
			
			if (!values.name) {
				errors.name = "This field must not be empty";
			}
		
			if (!values.email) {
				errors.email = "This field must not be empty";
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
				errors.email = 'Invalid email address';
			}
			
			if (!values.message) {
				errors.message = "This field must not be empty";
			}
		
			return errors;
		},
		onSubmit: values => {
			axios.post(`${process.env.REACT_APP_BASEURL_CMS}/api/forms/submit/contact?token=${process.env.REACT_APP_APIKEY_CONTACT_FORM}&__mailsubject=Pikartzo.com / Contact`, 
				{ "form": 
					{ 
						name: values.name, 
						email: values.email, 
						message: values.message,
					} 
				})
			.then(() => {				
				setTimeout(() => { 
					formik.resetForm();
				}, 3000);
			})
		}
	});
	
	return (
		<div className="fields">
		{props.title && <div className="title">{props.title}</div>}
			<div className="content-form">
				<form onSubmit={formik.handleSubmit}>
					<div className="form-input">
						<div className="form-field">
							<label htmlFor="name">Name :</label>
							
							<input
								id="name"
								name="name"
								type="text"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.name}
							/>
							
							{
								formik.touched.name 
								&& formik.errors.name 
								&& <div className="error">{formik.errors.name}</div> 
							}
						</div>
					
						<div className="form-field">
							<label htmlFor="email">Email :</label>
							
							<input
								id="email"
								name="email"
								type="text"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.email}
							/>
							
							{
								formik.touched.email 
								&& formik.errors.email 
								&& <div className="error">{formik.errors.email}</div>
							}
						</div>
					</div>
					
					<div className="form-textarea">
						<label htmlFor="message">Message :</label>
						
						<textarea 
							id="message" 
							name="message"
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							value={formik.values.message} 
						></textarea>
															
						{
							formik.touched.message 
							&& formik.errors.message 
							&& <div className="error">{formik.errors.message}</div>
						}
					</div>
					
					<div className="form-submit">
						{
							formik.isSubmitting
							? "Your message has been sent"
							: <input type="submit" value="Submit" />
						}
					</div>	
				</form>
			</div>
		</div>
	);
};

export default Form;
