import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import "./Resume.css";

function contentImages(dataField) {
	return (
		<img src={`${process.env.REACT_APP_BASEURL_CMS}/${dataField.value.path}`} alt={uuidv4()} />
	)
}

export default function Resume() {
	const [datas, setDatas] = useState({
		title_resume: "Data loading...",
		informations_resume: "<p>Data loading...</p>",
		link_music_resume_mp3: "",
		link_music_resume_ogg: "",
		link_attachment_resume: "",
		content_resume: []
	});
	
	const [isPlay, setIsPlay] = useState(false);
	
	const handlePlayPauseClick = () => {
		var player = document.getElementById("player");
		
		if (isPlay === false) {
			setIsPlay(!isPlay);
			player.play();
		}
		else {
			setIsPlay(!isPlay);
			player.pause();
		}
    }
	
	useEffect(() => {
		document.title = "Resume | Pikartzo";
		document.getElementById("body").classList.add("resume");
				
		axios.get(`${process.env.REACT_APP_BASEURL_CMS}/api/singletons/get/resume?token=${process.env.REACT_APP_APIKEY_RESUME}`)
		.then(d => {
			setDatas(d.data);
		})
		
		return () => {
			document.getElementById("body").classList.remove("resume");
		}
	  }, []);
			
	return (
		<div id="container" className="container">				
			{
				datas && 
					<>
						<div id="content" className="content">
							<div className="bloc-content">
								<div className="title-information-player-attachment">
									<div className="title-information">
										<div className="title" dangerouslySetInnerHTML = {{__html: datas.title_resume}}/>
										<div className="information" dangerouslySetInnerHTML = {{__html: datas.informations_resume}}/>
									</div>
								</div>
								{
								datas.link_music_resume && 
									<div className="player-attachment">
										<div className="player">
											{
												datas.link_music_resume && 
												<div onClick={handlePlayPauseClick}>
													<svg viewBox="0 0 50.8 23" xmlns="http://www.w3.org/2000/svg">
														<g className="fill">
															<path d="M34.4,0v20.6c0,0.3,0.1,0.6,0.2,0.9c0.4,0.8,1.2,1.4,2.2,1.4h1.3c1.3,0,2.4-1.1,2.4-2.4V0H34.4L34.4,0z"/>
															<path d="M0,2.8v17.3c0,1.3,1.4,2,2.4,1.4l17.4-10.1C14,8.1,8.2,4.8,2.4,1.4C1.4,0.8,0,1.6,0,2.8z"/>
															<path d="M44.8,0v20.6c0,1.3,1.1,2.4,2.4,2.4h1.3c1.3,0,2.4-1.1,2.4-2.4V0H44.8z"/>
														</g>
													</svg>
													
													<audio id="player">
														<source src={`${process.env.REACT_APP_BASEURL_CMS}/${datas.link_music_resume_ogg}`} type="audio/ogg" />
														<source src={`${process.env.REACT_APP_BASEURL_CMS}/${datas.link_music_resume_mp3}`} type="audio/mpeg" />
													</audio>
												</div>
											}
										</div>
										<div className="attachment">
											{
												datas.link_attachment_resume && 
												<a href={`${process.env.REACT_APP_BASEURL_CMS}/${datas.link_attachment_resume}`} target="_blank" rel="noreferrer" height="22.99" width="34.5">
													<svg viewBox="0 0 34.5 22.99" xmlns="http://www.w3.org/2000/svg">
														<g className="fill">
															<path d="M30.45,11.22v5a2.93,2.93,0,0,1-2.86,3H7.41a2.93,2.93,0,0,1-2.86-3v-5H.25v5A7.23,7.23,0,0,0,7.41,23.5H27.59a7.23,7.23,0,0,0,7.16-7.28v-5Z" transform="translate(-0.25 -0.5)"></path>
															<polygon points="17.21 16.34 17.23 16.32 17.28 16.36 17.85 15.77 24.48 9.36 21.98 6.78 19.04 9.63 19.04 0 15.46 0 15.46 9.63 12.52 6.78 10.02 9.36 16.59 15.71 17.21 16.34"></polygon>
														</g>
													</svg>
												</a>
											}
										</div>
									</div>
								}
							</div>
							
							<div className="bloc-content">
								<div className="bloc-content-images">				
									{ 
										datas.content_resume
										&& datas.content_resume.map((field) => {
											return <div key={uuidv4()}>{contentImages(field)}</div>
										})
									}
								</div>
							</div>
						</div>
					</>
			}
		</div>
	);
}